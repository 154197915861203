export const data = [
    {
        id: 1,
        titulo: "LANDING PAGE",
        precio: "",
        contenido: "Un diseño limpio, contenido claro y llamadas a la acción prominentes. Se enfocan en un solo objetivo, ofrecen información relevante.",
        items: ["Una página", 
        "Portada, info, quienes somos y contacto", 
        "Adaptable todos los dispositivos", 
        "Autoadministrable con WordPress", 
        "Animaciones en imagenes y textos",
        "Hasta 15 imagenes", 
        "Mail Corporativo", 
        "Formulario de contacto",
        "Google maps",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Tutoriales instructivos",
        "Ayuda y soporte gratis por 2 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta1.png",
      },
      {
        id: 2,
        titulo: "PROFESIONAL",
        precio: "",
        contenido: "Un sitio a tu medida, contenido personalizado, ideal para dar a conocer tus servicios como profesional o emprendimiento.",
        items: ["Hasta 5 páginas", 
        "Hasta 6 URLs o secciones", 
        "Adaptable todos los dispositivos", 
        "Autoadministrable con WordPress", 
        "Animaciones en imagenes y textos",
        "Hasta 30 imagenes", 
        "Mail Corporativo", 
        "Formulario de contacto",
        "Google maps",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Tutoriales instructivos",
        "Ayuda y soporte gratis por 3 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta2.png",
      },
      {
        id: 3,
        titulo: "PYMES",
        precio: "",
        contenido: "Perfecto para pequeñas y medianas empresas que buscan una sólida presencia digital para transmitir confianza y credibilidad a sus clientes.",
        items: ["Hasta 8 páginas", 
        "Hasta 10 URLs o secciones", 
        "Adaptable todos los dispositivos", 
        "Autoadministrable con WordPress", 
        "Animaciones en imagenes y textos",
        "Hasta 45 imagenes", 
        "Mail Corporativo", 
        "Formulario de contacto",
        "Google maps",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Tutoriales instructivos",
        "Ayuda y soporte gratis por 4 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta3.png",
      },
      {
        id: 4,
        titulo: "CORPORATIVO",
        precio: "",
        contenido: "Para grandes empresas que buscan destacarse en el mundo digital. Un sitio web que refleja la profesionalidad y la integridad de su marca.",
        items: ["Hasta 15 páginas", 
        "Hasta 20 URLs o secciones", 
        "Adaptable todos los dispositivos", 
        "Autoadministrable con WordPress", 
        "Animaciones en imagenes y textos",
        "Hasta 100 imagenes", 
        "Mail Corporativo", 
        "Formulario de contacto",
        "Google maps",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Tutoriales instructivos",
        "Ayuda y soporte gratis por 6 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta4.png",
      },
      {
        id: 5,
        titulo: "INICIAL",
        precio: "",
        contenido: "Categorías de productos ilimitadas. Ideal para empezar con tu emprendimiento o negocio y aumentar tus ventas.",
        items: ["Productos ilimitados", 
        "Cobros con MercadoPago",
        "Autoadministrable con WordPress", 
        "Animaciones en imagenes y textos",
        "Acceso a clientes para ver pedidos",
        "Carga de productos por lista Excel", 
        "Mail Corporativo", 
        "Formulario de contacto",
        "Google maps",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Tutoriales instructivos",
        "Ayuda y soporte gratis por 2 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta5.png",
      },
      {
        id: 6,
        titulo: "PROFESIONAL",
        precio: "",
        contenido: "Actualización de precios automáticas según dólar blue y más velocidad. Una tienda con fácil usabilidad para sus clientes.",
        items: ["Productos ilimitados", 
        "Cobros con MercadoPago, GetNet, UaláBis",
        "Act. de precios autom. segun dólar blue", 
        "Hosting más rápido", 
        "Autoadministrable con WordPress",
        "Acceso a clientes para ver pedidos",
        "Carga de productos por lista Excel",  
        "Animaciones en imagenes y textos",
        "Mail Corporativo",
        "Formulario de contacto",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Ayuda y soporte gratis por 4 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta6.png",
      },
      {
        id: 7,
        titulo: "EMPRESARIAL",
        precio: "",
        contenido: "Todo lo del plan PROFESIONAL más un medio de envío. Tienda completa con todas sus funciones y con un diseño único.",
        items: ["Productos ilimitados", 
        "Cobros con MercadoPago, GetNet, UaláBis",
        "Act. de precios autom. segun dólar blue", 
        "Hosting más rápido", 
        "Autoadministrable con WordPress",
        "Acceso a clientes para ver pedidos",
        "Carga de productos por lista Excel",  
        "Un medio de envío",
        "Mail Corporativo",
        "Formulario de contacto",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Ayuda y soporte gratis por 6 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta7.png",
      },
      {
        id: 8,
        titulo: "MAYORISTA",
        precio: "",
        contenido: "Con acceso a clientes para ventas mayoristas. Para una tienda con ventas mayoristas y minoristas, completa y rápida.",
        items: ["Productos ilimitados", 
        "Cobros con MercadoPago, GetNet, UaláBis",
        "Act. de precios autom. segun dólar blue", 
        "Hosting más rápido", 
        "Autoadministrable con WordPress",
        "Acceso a clientes para ver pedidos",
        "Carga de productos por lista Excel",  
        "Un medio de envío",
        "Acceso para ventas mayoristas",
        "Mail Corporativo",
        "Links a redes sociales",
        "WhatsApp flotante",
        "Ayuda y soporte gratis por 6 meses",
        "Hosting y dominio incluídos por un año"], 
        img: "tarjeta8.png",
      }
];

