import React from "react";

const Tiendas = () => {
  
    return (
        <>

        <div className="tiendas-main">
                    <h3 className="tiendas">Sitios web o tiendas online exclusivas para tu empresa o negocio</h3>
                    <p>
                    Una web que le da presencia digital a tu empresa, o una tienda online que te ayude a aumentar tus ventas.
                    </p>
                    <div className="container-tiendas">
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Web Autogestionable</h3>
                            <p>
                            Añade, edita y elimina páginas y productos fácilmente.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Integración con redes sociales</h3>
                            <p>
                            Conectamos la tienda online con tus redes sociales..
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Talles, colores...</h3>
                            <p>
                            Productos con variaciones de talles, colores, precios o cualquier otro atributo.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Pagos</h3>
                            <p>
                            Conectamos la tienda online para que paguen con tarjetas.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Inventario</h3>
                            <p>
                            Controla en inventario y configura alarmas de stock bajo.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Productos ilimitados</h3>
                            <p>
                            Subis todos los productos que quieras.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Informes de ventas, clientes y stock</h3>
                            <p>
                            Genera informes de ventas y de clientes para tener controlado tu negocio.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Productos ilimitados</h3>
                            <p>
                            Subis todos los productos que quieras.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Precio según el dólar</h3>
                            <p>
                            Actualización de precios automaticamente segun valor el dólar para vender en pesos.
                            </p>
                        </div>
                        <div className="tiendas-check ocultar">
                            <h3>&#10003; Entrega en menos de 15 días</h3>
                            <p>
                            Desarrollo ágil y rápido para hacerlo en el menor tiempo posible.
                            </p>
                        </div>
                    </div>
        </div>
        </>
    );
  };

  export default Tiendas;