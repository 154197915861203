import React from "react";
import Portf from "../componentes/Portf";
import { useEffect } from "react";



const Portfolio = () => {
    useEffect(() => {
        const seccionesOcultas = document.querySelectorAll('.ocultar');
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle('mostrar', entry.isIntersecting);
          });
        }, { threshold: 0.5 });
    
        seccionesOcultas.forEach((seccion) => observer.observe(seccion));
    
        return () => {
          seccionesOcultas.forEach((seccion) => observer.unobserve(seccion));
        };
      }, []);
  
    return (
        <>
        <Portf/>
        </>
    );
  };

  export default Portfolio;