import './App.css';
import Navbar from './componentes/Nav';
import Footer from "./componentes/Footer";
import Inicio from './pages/Inicio';
import { useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import TiendasOnline from './pages/TiendasOnline';
import Portfolio from './pages/Portfolio';
import Contacto from './pages/Contacto';

  

function App() {
  
  useEffect(() => {
    const seccionesOcultas = document.querySelectorAll('.ocultar');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle('mostrar', entry.isIntersecting);
      });
    }, { threshold: 0.5 });

    seccionesOcultas.forEach((seccion) => observer.observe(seccion));

    return () => {
      seccionesOcultas.forEach((seccion) => observer.unobserve(seccion));
    };
  }, []);

  return (
        <div className="App">

              <Navbar/>
              <Routes>
              <Route path="/" element={<Outlet />}/>
              <Route index element={<Inicio />} />
              <Route path="sitiosweb" element={<TiendasOnline/>} />
              <Route path="portfolio" element={<Portfolio/>} />
              <Route path="contacto" element={<Contacto/>} />
              </Routes>
              <Footer/>

        </div>
  );
}

export default App;
