export const data2 = [
    {
        id: 1,
        logo: "/img/glogo.png",
        width: "200px",
        texto: "Centro especializado en servicio técnico y venta de productos Apple.",
        link: "https://www.giulitel.com",
        img: "/img/giulitel.png"
    },
    {
        id: 2,
        logo: "/img/PERFIL2.png",
        width: "120px",
        texto: "Fabricación y venta de sillas gamer artesanales.",
        link: "https://www.herrerogamer.com.ar",
        img: "/img/hgamer.png"
    },
    {
        id:3,
        logo: "/img/logovdk3.png",
        width: "250px",
        texto: "Criadero de ovejeros alemanes.",
        link: "https://www.vonderknina.com.ar",
        img: "/img/vonder.png"
    },
    {
        id: 4,
        logo: "/img/logovet.png",
        width: "120px",
        texto: "Centro veterinario clínico especializado en oncología.",
        link: "https://www.centrovetonco.com.ar",
        img: "/img/centrovet.png"

    },
    {
        id: 5,
        logo: "/img/logolum.png",
        width: "250px",
        texto: "Alquiler diario de apartamentos en Río Cuarto.",
        link: "https://www.lumawa.com.ar",
        img: "/img/lumawa.png"
    },
    {
        id: 6,
        logo: "/img/logodj.png",
        width: "150px",
        texto: "Distribuidora de productos para ferreterías y pinturerías.",
        link: "https://distribuidoradonjuan.com.ar",
        img: "/img/donjuan.png"
    },
    {
        id: 7,
        logo: "/img/logoint.png",
        width: "200px",
        texto: "Torneos y venta de mod's para el Simracing.",
        link: "https://www.interligaproracing.com",
        img: "/img/interl.png"
    }

]

