import React from "react";
import Bienvenidos from "../componentes/Bienv";
import Habilidades from "../componentes/Hab";
import Pasos from "../componentes/Pasos";
import { useEffect } from "react";

const Inicio = () => {

    useEffect(() => {
        const seccionesOcultas = document.querySelectorAll('.ocultar');
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            entry.target.classList.toggle('mostrar', entry.isIntersecting);
          });
        }, { threshold: 0.5 });
    
        seccionesOcultas.forEach((seccion) => observer.observe(seccion));
    
        return () => {
          seccionesOcultas.forEach((seccion) => observer.unobserve(seccion));
        };
      }, []);
  
    return (
        <>
        <Bienvenidos/>
        <Habilidades/>
        <Pasos/>
        </>
    );
  };

  export default Inicio;