import React from 'react';
import { data2 } from "../componentes/Data2";

const Portf = () => {


    
    const Portfolio = ({logo, width, texto, link, img}) => (
        <>
        <div className="container-portf2 ocultar">
            <div className="texto-portf">
                <img className='portf-logo' src={logo} style={{width: width}}/>
                <p>{texto}</p>
            </div>
            <a href={link} target="_blank">
                <img className='portf-img' src={img} style={{width: 500}}/>
            </a> 
        </div>
            <div className='cont-separador'>
                <div className="separador"></div>
            </div>   
        </>
    );


    return (
    
            <div className='container-portf'>
                            <h3 className='portfolio'>Te presento mi portfolio!</h3>
                            <p className='portfolio2'>Diseños únicos adaptados a la necesidad de cada cliente</p>
                            <div className='cont-separador'>
                                <div className="separador"></div>
                            </div>
                    {data2.map((tarjeta) => (
                        <Portfolio
                            key={tarjeta.id}
                            logo={tarjeta.logo}
                            width={tarjeta.width}
                            texto={tarjeta.texto}
                            link={tarjeta.link}
                            img={tarjeta.img}
                        />
                    ))}
            </div>
        );
};

export default Portf;

