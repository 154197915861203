import React from 'react';
import { data } from "../componentes/Data";

const seccionTarjetas = () => {

    
    const primerasCuatroTarjetas = data.slice(0, 4);
    const tarjetasRestantes = data.slice(4);

    
    const Tarjetas = ({titulo, precio, contenido, items, img}) => (
        <div className="container-tarjetas ocultar">
            <div className="texto" style={{ backgroundImage: `url(/img/${img})`, borderRadius: "20px" }}>
                <h4>{titulo}</h4>
                <h4 className='precio'>{precio}</h4>
                <p>{contenido}</p>
            </div>
            <div className='items'>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item}
                    </li>
                ))}
            </ul>
            </div>
            <a href="https://walink.co/29a421" target="_blank">MAS INFO</a> 
        </div>
    );


    return (
    
            <div className='main-tarjetas'>
                <h3>Tu sitio web a medida</h3>
                <p>Pensada para vos! Pago único, sin gastos de mantenimiento.</p>
    
                <div className="seccion-tarjetas">
                    {primerasCuatroTarjetas.map((tarjeta) => (
                        <Tarjetas
                            key={tarjeta.id}
                            titulo={tarjeta.titulo}
                            precio={tarjeta.precio}
                            contenido={tarjeta.contenido}
                            items={tarjeta.items}
                            img={tarjeta.img}
                        />
                    ))}
                </div>
    
                <div className="main-tarjetas">
                    <h3>Podés tener tu tienda online</h3>
                    <p>Pensada para vos! Pago único, sin gastos de mantenimiento.</p>
                </div>
    
                <div className="seccion-tarjetas">
                    {tarjetasRestantes.map((tarjeta) => (
                        <Tarjetas
                            key={tarjeta.id}
                            titulo={tarjeta.titulo}
                            precio={tarjeta.precio}
                            contenido={tarjeta.contenido}
                            items={tarjeta.items}
                            img={tarjeta.img}
                        />
                    ))}
                </div>

                <h3 className='planes'>Para sitios creados con React podés pedirme presupuesto sin cargo!</h3>
            </div>
        );
};

export default seccionTarjetas;