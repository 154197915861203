import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../componentes/styles.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const history = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); 
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? "active" : ""}`}>
      <Link to="/inicio">
        <img className="navbar-logo" src="./img/logo.png" alt="Logo" width={100} />
      </Link>
      <div className={`navbar-links ${isOpen ? "active" : ""}`}>
        <Link to="/" onClick={handleLinkClick}>
          Inicio
        </Link>
        <Link to="/sitiosweb" onClick={handleLinkClick}>
          Sitios Web
        </Link>
        <Link to="/portfolio" onClick={handleLinkClick}>
          Portfolio
        </Link>
        <Link to="/contacto" onClick={handleLinkClick}>
          Contacto
        </Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <div className={`bar ${isOpen ? "toggle" : ""}`}></div>
        <div className={`bar ${isOpen ? "toggle" : ""}`}></div>
        <div className={`bar ${isOpen ? "toggle" : ""}`}></div>
      </div>
      <Outlet />
    </nav>
  );
};

export default Navbar;