import React from "react";

const Pasos = () => {



    return (
        <>

        <div className="seccion-pasos">
            <div className="pasos">
                    <h3>PRIMEROS PASOS</h3>
                    <div className="pasos-main">
                        <div className="container-pasos ocultar">
                            <h3>#1 Hablemos</h3>
                            <p>
                            Contame todos los detalles que desees incluir en tu nueva página web, 
                            especialmente aquellos que sean fundamentales para el proyecto, como el logotipo, 
                            los contenidos, la estructura, los estilos, entre otros aspectos importantes.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>#2 Investigación</h3>
                            <p>
                            En esta fase, se investiga sobre tu audiencia y competidores, es una parte esencial en este proceso. Al mismo tiempo, 
                            nos enfocamos en la creación del backend de tu proyecto web.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>#3 Diseño</h3>
                            <p>
                            Una vez mostradas las propuestas de diseño web, nos señalas cualquier modificación que consideres adecuado. 
                            Analizamos las propuestas juntos y luego implementamos las sugerencias.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>#4 Revisión y entrega</h3>
                            <p>
                            Hacemos una última revisión y realizamos las correcciones y modificaciones finales ¡En ese momento tu nueva web ya estará visible para tus clientes!
                            </p>
                        </div>


                        <div className="container-pasos ocultar">
                            <h3>Proceso rápido</h3>
                            <p>
                            Diseño y desarrollo de páginas web siguiendo un método increíblemente ágil y sencillo.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>Adaptadas a móviles y tablets</h3>
                            <p>
                            Tu web se adaptará automaticamente a móviles y tablets para que no pierdas ningún cliente que se conecte desde dispositivos móviles.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>Contenido autogestionable</h3>
                            <p>
                            Si elegís hacer tu web con WordPress, podés añadir, editar y eliminar el contenido vos mismo.
                            </p>
                        </div>

                        <div className="container-pasos ocultar">
                            <h3>Integración con redes sociales</h3>
                            <p>
                            Accesos a tus redes sociales y, si quieres, hasta podemos mostrar el contenido directamente.
                            </p>
                        </div>
                    </div>
            </div>
        </div>


        </>
    );
  };

  export default Pasos;