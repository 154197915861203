import React from "react";
import Habilidades from "./Hab";

const Bienvenidos = () => {
  
    return (
        <>

    <div className="bienv-main">
        <div className="container-bienv">
        <img className="img-bienv" src="img/Sin títfuglo.png" width={350} alt=""/>
            <h2>Desarrollador Full Stack</h2>
            <h3>Estoy para hacer realidad tus ideas y proyectos!</h3>
            <h1 className="paginaweb">
            Podés tener tu sitio web único y profesional segun tus necesidades!
            </h1>
        </div>

        <div className="container-bienv2">
            <img src="/img/avatar.png" width={300} alt=""/>
            <p>¡Hola! Soy Hernan Bergara, tengo 25 años y vivo en Santa Fe, Argentina.</p>
            <p>
            Con experiencia en el diseño de interfaces intuitivas e innovadoras, transformo ideas en sitios web
             y tiendas online funcionales y atractivas. ¡Estoy para ayudarte!
            </p>
            <div className="boton-bienv">
            <a href="#habilidades">&#187;</a>
            </div>
        </div>
    </div>
        </>
    );
  };

  export default Bienvenidos;